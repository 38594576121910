import React from "react";

export function SocialLinks(props) {
  const resumeData = props.resumeData;
  const classNameForContainer = props.classNameForContainer ?? "";
  return (
    <ul className={classNameForContainer}>
      {resumeData.socialLinks &&
        resumeData.socialLinks.map((item) => {
          return (
            <li key={item.name}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <i className={item.className} />
              </a>
            </li>
          );
        })}
    </ul>
  );
}
