import React from "react";
import "./VideoResume.css";

export function VideoResume() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="video-container embed-responsive">
            <iframe
              title="video resume"
              src="https://www.youtube.com/embed/w6kNSuqhKHM?modestbranding=1&amp;rel=0"
              frameBorder="0"
              gesture="media"
              allow="encrypted-media"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
