let resumeData = {
  imagebaseurl:
    "https://media-exp2.licdn.com/dms/image/C5603AQEhoTZN4VVGRA/profile-displayphoto-shrink_800_800/0?e=1585785600&v=beta&t=JJFrnJGFExF1RNKR1KW1gT-ADkCo8yur-T8672b9KAM",
  name: "Tien Choong William Mun",
  role: "Project Manager",
  linkedinId: "williammun",
  skypeid: "Your skypeid",
  roleDescription:
    "with more than 15 years of experience in Construction Management",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/williammun",
      className: "fa fa-linkedin",
    },
  ],
  aboutme: [
    "A hardworking and reliable Civil Engineer, with more than 15 years of experience in Construction Management.",
    "My established career as a Construction Manager has helped ensure the success of the many projects that I have overseen.",
    "I believe my wealth of knowledge and management skills will bring success to any business that i am part of",
  ],
  address: "Melbourne, Australia",
  website: "https://www.linkedin.com/in/williammun",
  education: [
    {
      UniversityName: "Nanyang Technological University",
      specialization:
        "Master of Science (International Construction Management)",
      MonthOfPassing: "Dec",
      YearOfPassing: "1998",
      Achievements: "",
    },
    {
      UniversityName: "National University of Singapore",
      specialization: "Bachelor of Engineering (Civil)",
      MonthOfPassing: "June",
      YearOfPassing: "1989",
      Achievements: "",
    },
  ],
  work: [
    {
      CompanyName: "Greatearth Construction Pte Ltd",
      specialization: "Project Director",
      MonthOfLeaving: "March",
      YearOfLeaving: "2010",
      Achievements: "",
    },
    {
      CompanyName:
        "Hydrochem (S) Pte Ltd [Wholly owned subsidiary of Hyflux Ltd]",
      specialization: "Construction Manager",
      MonthOfLeaving: "July",
      YearOfLeaving: "2013",
      Achievements: "",
    },
    {
      CompanyName: "Shimizu-Total Joint Operation, Jakarta",
      specialization: "Senior Design Coordinator",
      MonthOfLeaving: "July",
      YearOfLeaving: "2015",
      Achievements: "",
    },
  ],
  // "skillsDescription":"Your skills here",
  // "skills":[
  //   {
  //     "skillname":"HTML5"
  //   },
  //   {
  //     "skillname":"CSS"
  //   },
  //   {
  //     "skillname":"Reactjs"
  //   }
  // ],
  portfolio: [
    {
      name: "project1",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg",
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png",
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg",
    },
  ],
  // "testimonials":[
  //   {
  //     "description":"This is a sample testimonial",
  //     "name":"Some technical guy"
  //   },
  //   {
  //     "description":"This is a sample testimonial",
  //     "name":"Some technical guy"
  //   }
  // ]
};

export default resumeData;
